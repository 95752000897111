.ant-menu {
  background-color: #FFF !important;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1) !important;
}

.ant-layout-content {
  background-color: #F1F2F5 !important;
}

.site-layout-background {
  background-color: #FFFFFF !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.ant-card {
  background-color: #FFF !important;
  border: none !important;
}

.ant-card .ant-card-head {
  border: none !important;
}

.ant-card .ant-card-head-title {
  color: #050505 !important;
}

.ant-card .ant-card-meta-title {
  color: #050505;
}

.ant-card .ant-card-meta-description {
  color: #050505 !important;
}

.ant-card .ant-card-actions {
  background-color: #FFF !important;
  border: none !important;
  color: #050505 !important;
}

.ant-card .ant-card-actions li {
  color: #050505 !important;
  border: none !important;
  border-inline-end: none !important;
}

.ant-input-affix-wrapper {
  background-color: #EFF2F5 !important;
  padding: 0 11px !important;
  border: none !important;
}

.ant-input {
  background-color: #EFF2F5 !important;
  border: none !important;
  height: 45px;
  color: #050505;
  font-size: 16px;
}

.ant-input.whiteSearch {
  background-color: #fff !important;
}

.ant-input::placeholder {
  color: #5F6266 !important;
}

.ant-btn {
  background-color: #E3E6EA !important;
  color: #050505 !important;
  border: none !important;
}

.ant-btn:hover {
  background-color: #D7DADE !important;
  background-color: #bbc0c7 !important;
}

.ant-btn-primary {
  background-color: #00172A !important;
  color: #fff !important;
}

.ant-btn-primary:hover {
  background-color: #004177 !important;
}

.ant-btn-success {
  background-color: #d66b39 !important;
  color: #fff !important;
}

.ant-btn-success:hover {
  background-color: #df8b63 !important;
}

.ant-btn-green {
  background-color: #70b56c !important;
  color: #fff !important;
}

.ant-btn-green:hover {
  background-color: #91c68e !important;
}

.ant-btn-transparent {
  background-color: transparent !important;
}

.ant-menu-item {
  height: 55px !important;
  color: #050505;
  font-weight: 500;
  font-size: 14px;
}

.ant-menu-item .ant-menu-item-icon {
  width: 45px;
  height: 45px;
  font-size: 22px !important;
  margin-right: 10px;
  background-color: #E3E6EA;
  padding: 10px;
  border-radius: 50%;
}

.ant-menu-item .profilePic.ant-menu-item-icon {
  padding: 0 !important;
}

.ant-menu-item.ant-menu-item-selected {
  background-color: #fff !important;
}

.newPostBox {
  width: 100%;
  color: #64676B;
  cursor: text;
  background-color: #EFF2F5 !important;
  border: none !important;
  height: 45px;
  color: #050505;
  font-size: 16px;
  padding: 10px 15px;
  border-radius: 20px;
}

.ant-select-selector {
  height: 45px !important;
  background-color: #EFF2F5 !important;
  font-size: 16px !important;
}

/* Style the dropdown options */
.ant-select-dropdown .ant-select-item {
  height: 45px !important;
  line-height: 45px !important;
  /* to vertically center the text */
  background-color: #EFF2F5 !important;
  font-size: 16px !important;
}

.ant-picker, .ant-picker-date,
.ant-picker-time {
  background-color: #EFF2F5;
  height: 45px;
}

.ant-picker .ant-picker-input, .ant-picker-date .ant-picker-input,
.ant-picker-time .ant-picker-input {
  height: 45px;
}

.ant-picker .ant-picker-input input, .ant-picker-date .ant-picker-input input,
.ant-picker-time .ant-picker-input input {
  height: 43px;
  background-color: #EFF2F5;
}

.ant-picker .ant-picker-suffix, .ant-picker-date .ant-picker-suffix,
.ant-picker-time .ant-picker-suffix {
  line-height: 45px;
}

.ant-picker .ant-picker-clear, .ant-picker-date .ant-picker-clear,
.ant-picker-time .ant-picker-clear {
  line-height: 45px;
}
