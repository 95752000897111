body {
  font-family: 'Roboto', sans-serif !important;
}

.responsive-iframe-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  margin-top: 30px;
}

.responsive-iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ant-image {
  width: 100% !important;
}

.breadcrumbContainer {
  font-size: 20px;
  position: relative;
}

.breadcrumbContainer .breadCrumb {
  cursor: pointer;
  position: relative;
}

.breadcrumbContainer .breadCrumb.lastBreadCrumb .caret {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid #333;
  margin-left: 5px;
}

.breadcrumbContainer .breadcrumbMenu {
  display: none;
  position: absolute;
  right: 0;
  top: 100%;
  border: 1px solid #ccc;
  background-color: #fff;
  z-index: 10;
}

.breadcrumbContainer .breadcrumbMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.breadcrumbContainer .breadcrumbMenu ul li {
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
  cursor: pointer;
}

.breadcrumbContainer .breadcrumbMenu ul li:hover {
  background-color: #eaeaea;
}

.breadcrumbContainer .breadcrumbMenu ul li:last-child {
  border-bottom: none;
}

.folder-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
  padding: 30px 16px 16px 0;
  width: 100%;
}

.folder-container .folder {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  transition: transform 0.2s ease-in-out;
  background-color: #e8eaed;
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.folder-container .folder:hover {
  transform: scale(1.05);
}

.folder-container .folder-icon {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-right: 10px;
}

.folder-container .folder-label {
  flex-grow: 1;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ant-btn-danger {
  background-color: red;
  color: white;
}

.ant-btn-success {
  background-color: green;
  color: white;
}

.ant-btn-orange {
  background-color: orange;
  color: white;
}

.file-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(275.75px, 1fr));
  gap: 16px;
  padding: 16px;
  width: 100%;
}

.file-container .file {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  width: 275.75px;
  background-color: #e8eaed;
  border-radius: 8px;
  padding: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.file-container .file:hover {
  transform: scale(1.05);
}

.file-container .file-preview {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.file-container .file-preview img {
  width: 100%;
  height: auto;
}

.file-container .file-label {
  text-align: center;
  padding: 8px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.breadCrumb {
  display: inline;
  cursor: pointer;
}

.chat-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  height: 80vh;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.chat-container .messages {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}

.chat-container .messages .message-group {
  display: flex;
  flex-direction: column;
}

.chat-container .messages .date-label {
  text-align: center;
  margin: 15px 0;
  font-weight: bold;
  color: #a0a0a0;
}

.chat-container .messages .message {
  padding: 10px 20px;
  margin: 5px;
  border-radius: 8px;
  max-width: 70%;
  align-self: flex-start;
  display: flex;
  align-items: center;
}

.chat-container .messages .message .text {
  flex-grow: 1;
}

.chat-container .messages .message .timestamp {
  margin-left: 10px;
  font-size: 0.8em;
  color: #a0a0a0;
}

.chat-container .messages .message .avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 1px solid rgba(255, 255, 255, 0.8);
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
  flex-shrink: 0;
}

.chat-container .messages .message .avatar img {
  width: 100%;
  height: 100%;
}

.chat-container .messages .message.own {
  background-color: #DCF8C6;
  align-self: flex-end;
}

.chat-container .messages .message.other {
  background-color: #EDEDED;
}

.chat-container .input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  border-top: 1px solid #e0e0e0;
}

.chat-container .input-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #d9d9d9;
  margin-right: 10px;
}

.chat-wrapper {
  display: flex;
  height: 80vh;
}

.chat-sidebar {
  width: 200px;
  padding-right: 10px;
  border-right: 1px solid #e0e0e0;
  overflow-y: auto;
}

.chat-sidebar .chat-room {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.chat-sidebar .chat-room:hover {
  background-color: #dadada;
}

.chat-sidebar .chat-room.active {
  background-color: #001628;
  color: white;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  /* Adjust as needed for spacing between images */
}

.grid-container.two {
  display: grid;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 16px;
  /* Adjust as needed for spacing between images */
}

.grid-item {
  width: 100%;
  position: relative;
  overflow: hidden;
  /* This will hide any part of the image that doesn't fit */
  transition: 0.3s ease;
}

.grid-item:hover {
  opacity: 0.7;
  /* Dim the image a bit on hover */
}

.preview-text {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  padding: 5px 10px;
  border-radius: 5px;
}

.grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.grid-item:hover .preview-text {
  display: block;
}

.memberContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  /* Optional, if you want some space between items */
}

.memberContainerSmall {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 20px;
  /* Optional, if you want some space between items */
}

.eventContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  /* Optional, if you want some space between items */
}

.eventContainerSmall {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  /* Optional, if you want some space between items */
}

.eventType {
  padding: 30px;
  border: 1px solid #f1f1f1;
  border-radius: 20px;
  cursor: pointer;
}

.eventType:hover {
  background-color: #f1f1f1;
}

.eventType.selected {
  background-color: #70b56c;
  color: #fff;
}

.floating-verification {
  display: inline;
}

.careerItem {
  padding: 10px;
  cursor: pointer;
  position: relative;
}

.careerItem .icon {
  display: none;
  position: absolute;
  right: 15px;
  top: 15px;
}

.careerItem:hover {
  background-color: #f1f1f1;
}

.careerItem:hover .icon {
  display: inherit;
}

.productItem {
  align-content: 'center';
  padding: 15px 5px;
  border-bottom: 1px solid #f1f1f1;
}

.running {
  text-align: center;
  margin-top: 100px;
}

.running .text {
  font-size: 18px;
  font-weight: 800;
  margin-top: 20px;
  margin-bottom: 5px;
}

.dbCard {
  margin-bottom: 16px;
}

.dbCard .dbStat {
  font-size: 30px;
  font-weight: 700;
}

.dbCard h3 {
  margin-top: 0;
  margin-bottom: 5px;
}

.loader {
  border: 4px solid #f3f3f3;
  /* Light grey background */
  border-top: 4px solid #FFD100;
  border-radius: 50%;
  width: 40px;
  margin: 80px auto;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.menu {
  margin: 0;
  padding: 0;
}

.menu li {
  display: inline;
  margin: 0 30px;
  cursor: pointer;
}

.menu li.active {
  font-weight: bold;
  text-decoration: underline;
}
